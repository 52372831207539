





























































import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";
import { ava, pChain } from "@/AVA";
import { BN } from "avalanche";

import ValidatorRow from "@/components/misc/ValidatorList/ValidatorRow.vue";
import FilterSettings from "@/components/misc/ValidatorList/FilterSettings.vue";
import {
  ValidatorRaw,
  ValidatorDict,
} from "@/components/misc/ValidatorList/types";
import Tooltip from "@/components/misc/Tooltip.vue";
import { ValidatorListItem } from "@/store/modules/platform/types";
import { ValidatorListFilter } from "@/components/wallet/earn/Delegate/types";
import { filterValidatorList } from "@/components/wallet/earn/Delegate/helper";

@Component({
  components: { Tooltip, ValidatorRow, FilterSettings },
  methods: {
    displayNodeID(nodeID) {
      // Replace "NodeID" prefix with "VectorID"
      return nodeID.replace(/^NodeID/, "DijetsNode");
    },
  },
})
export default class ValidatorsList extends Vue {
  @Prop() search!: string;
  showFilter = false;
  filter: ValidatorListFilter | null = null;

  openFilters() {
    this.showFilter = true;
  }

  hideFilters() {
    this.showFilter = false;
  }

  applyFilter(filter: ValidatorListFilter | null) {
    this.filter = filter;
  }

  get validators(): ValidatorListItem[] {
    let list: ValidatorListItem[] = this.$store.getters[
      "Platform/validatorListEarn"
    ];

    if (this.search) {
      list = list.filter((v) => {
        return v.nodeID.includes(this.search);
      });
    }

    // order by stake amount
    list = list.sort((a, b) => {
      let amtA = a.validatorStake;
      let amtB = b.validatorStake;

      if (amtA.gt(amtB)) {
        return -1;
      } else if (amtA.lt(amtB)) {
        return 1;
      } else {
        return 0;
      }
    });

    return list;
  }

  get validatorsFiltered(): ValidatorListItem[] {
    return filterValidatorList(this.validators, this.filter);
  }

  onselect(val: ValidatorListItem) {
    this.$emit("select", val);
  }
}
